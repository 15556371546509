import React, { useMemo, useState } from "react";

import { Col, Row } from "antd";

import { NARROW_NAVBAR_SIZE, NAVBAR_SIZE } from "../../../constants/navigation";
import Navigation from "../Navigation/Navigation";

const MainWrapper = ({ children }: { children: React.ReactNode }) => {
    const [navbarIsCollapsed, setNavbarIsCollapsed] = useState(
        localStorage.getItem("navbarIsCollapsed") === "true" || false
    );

    const navbarSize = useMemo(() => {
        return navbarIsCollapsed ? NARROW_NAVBAR_SIZE : NAVBAR_SIZE;
    }, [navbarIsCollapsed]);

    return (
        <div style={{ minWidth: 1280 }}>
            <Row>
                <Col
                    flex={`${navbarSize}px`}
                    className={"white-background-color"}
                    style={{
                        height: "100dvh",
                        position: "fixed",
                        zIndex: 11,
                    }}
                >
                    <Navigation
                        navbarIsCollapsed={navbarIsCollapsed}
                        setNavbarIsCollapsed={setNavbarIsCollapsed}
                        navbarWidth={navbarSize}
                    />
                </Col>
                <Col
                    flex={"auto"}
                    className={"grey-1-background-color"}
                    style={{
                        height: "100dvh",
                        width: "100%",
                        paddingLeft: navbarSize,
                    }}
                >
                    {children}
                </Col>
            </Row>
        </div>
    );
};

export default MainWrapper;
