import { createApi } from "@reduxjs/toolkit/query/react";

import { IGetClientsResponse, IGetClientByIdResponse, IGetAllClientsEmailsResponse } from "../../types/clients";
import { IGetListQuery, getListQueryString } from "../../types/queries";
import { baseQueryWithIntercept } from "../connection/api";

export const clientApi = createApi({
    reducerPath: "clientApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["clients"],
    endpoints: builder => ({
        getClients: builder.query<IGetClientsResponse, IGetListQuery>({
            query: query => ({
                url: `/clients?${getListQueryString(query)}`,
            }),
            providesTags: ["clients"],
        }),
        getClientById: builder.query<IGetClientByIdResponse, string>({
            query: id => ({ url: `/clients/${id}` }),
            providesTags: ["clients"],
        }),
        getAllClientsEmails: builder.query<IGetAllClientsEmailsResponse, void>({
            query: () => "/clients/all-emails",
        }),
    }),
});

export const { useGetClientsQuery, useGetClientByIdQuery, useGetAllClientsEmailsQuery } = clientApi;
