import { createApi } from "@reduxjs/toolkit/dist/query/react";

import {
    IGetMainQuestionnaireQuery,
    IGetMainQuestionnaireResponse,
    IUpdateQuestionnaireByIdBody,
    IUpdateQuestionnaireByIdResponse,
} from "../../types/questionnaires";
import { baseQueryWithIntercept } from "../connection/api";

const ENDPOINT_PREFIX = "questionnaires";

export const questionnairesApi = createApi({
    reducerPath: "questionnairesApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Questionnaire"],
    endpoints: builder => ({
        createQuestionnaire: builder.mutation<any, any>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}`,
                method: "POST",
                body: data,
            }),
        }),
        getMainQuestionnaire: builder.query<IGetMainQuestionnaireResponse, IGetMainQuestionnaireQuery>({
            query: () => ({
                url: `${ENDPOINT_PREFIX}/full-main`,
            }),
            providesTags: ["Questionnaire"],
        }),
        updateQuestionnaireById: builder.mutation<IUpdateQuestionnaireByIdResponse, IUpdateQuestionnaireByIdBody>({
            query: data => ({
                url: `${ENDPOINT_PREFIX}/${data.questionnaireId}`,
                method: "PUT",
                body: data.data,
            }),
            invalidatesTags: ["Questionnaire"],
        }),
    }),
});

export const { useGetMainQuestionnaireQuery, useUpdateQuestionnaireByIdMutation, useCreateQuestionnaireMutation } =
    questionnairesApi;
