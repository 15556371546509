import { createApi } from "@reduxjs/toolkit/query/react";
import { message } from "antd";

import { setTokenToCookies } from "../../shared/cookies-actions";
import { ISignUpPayload, ISignUpResponse, ISignInResponse, ISignInPayload } from "../../shared/interfaces/auth";
import { IMessage } from "../../types/common";
import { baseQueryWithIntercept } from "../connection/api";

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Auth"],
    endpoints: builder => ({
        signUp: builder.mutation<ISignUpResponse, ISignUpPayload>({
            query: data => ({
                url: "/users",
                method: "POST",
                data,
            }),
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    setTokenToCookies(data.token);
                } catch (error: any) {
                    message.error((error?.error?.data as IMessage)?.message);
                }
            },
        }),
        signIn: builder.mutation<ISignInResponse, ISignInPayload>({
            query: data => ({
                url: "/users/sign-in",
                method: "POST",
                body: data,
            }),
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    setTokenToCookies(data.token);
                } catch (error: any) {
                    message.error((error?.error?.data as IMessage)?.message);
                }
            },
        }),
    }),
});

export const { useSignUpMutation, useSignInMutation } = authApi;
