import { useEffect, useState } from "react";

import { BsFileText, BsPerson, BsListColumnsReverse, BsPersonLinesFill } from "react-icons/bs";
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { RiQuestionnaireLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";

import NavigationItem from "./components/NavigationItem";
import { NavigationContext } from "./contexts/NavigationContext";
import Logo from "../../../assets/icons/logo.svg";

const Navigation = ({
    navbarWidth,
    navbarIsCollapsed,
    setNavbarIsCollapsed,
}: {
    navbarWidth: number;
    navbarIsCollapsed: boolean;
    setNavbarIsCollapsed: (_navbarIsCollapsed: boolean) => void;
}) => {
    const location = useLocation();

    const [selectedPath, setSelectedPath] = useState(location.pathname);

    useEffect(() => {
        setSelectedPath(location.pathname);
    }, [location.pathname]);

    return (
        <NavigationContext.Provider
            value={{
                navbarIsCollapsed,
                selectedPath,
                onPathSelect: setSelectedPath,
            }}
        >
            <div style={{ width: navbarWidth }}>
                <div style={{ height: "50px", width: navbarIsCollapsed ? "40px" : "auto", marginLeft: "20px", display: "flex", overflow: "hidden" }} className={"mt20"}>
                    <img alt={"about us"} src={Logo}/>
                </div>

                <div style={{ height: "80vh" }} className={navbarIsCollapsed ? "ph12" : "ph20"}>
                    <div className={"mt10"}>
                        <NavigationItem icon={<BsPersonLinesFill />} label={"Clients"} path={"/clients"} />
                    </div>

                    <div className={"mt10"}>
                        <NavigationItem icon={<BsListColumnsReverse />} label={"Company List"} path={"/company-list"} />
                    </div>

                    <div className={"mt10"}>
                        <NavigationItem
                            icon={<BsFileText />}
                            label={"Questionnaire Constructor"}
                            path={"/questionnaire/constructor"}
                        />
                    </div>

                    <div className={"mt10"}>
                        <NavigationItem
                            icon={<RiQuestionnaireLine />}
                            label={"Questionnaire Preview"}
                            path={"/questionnaire/preview"}
                        />
                    </div>

                    <div className={"mt10"}>
                        <NavigationItem icon={<BsPerson />} label={"Profile"} path={"/profile"} />
                    </div>
                </div>

                <div style={{ height: "10vh" }} className={navbarIsCollapsed ? "ph12" : "ph20"}>
                    <div className={navbarIsCollapsed ? "horizontal-center" : "horizontal-right"}>
                        {navbarIsCollapsed ? (
                            <MdOutlineKeyboardDoubleArrowRight
                                className={"grey-4-color pointer fs20"}
                                style={{ marginTop: -20 }}
                                onClick={() => {
                                    setNavbarIsCollapsed(false);
                                    localStorage.setItem("navbarIsCollapsed", "false");
                                }}
                            />
                        ) : (
                            <MdOutlineKeyboardDoubleArrowLeft
                                className={"grey-4-color pointer fs26"}
                                onClick={() => {
                                    setNavbarIsCollapsed(true);
                                    localStorage.setItem("navbarIsCollapsed", "true");
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </NavigationContext.Provider>
    );
};

export default Navigation;
