import qs from "qs";

import { IFilter, ISortBy } from "./common";

export interface IGetListQuery {
    pageIndex?: number;
    pageSize?: number;
    filters?: IFilter[];
    sortBy?: ISortBy[];
    globalFilter?: string;
    ids?: string[];
}

export const withPagination = (pageIndex: number, pageSize: number) => {
    return {
        page: pageIndex,
        limit: pageSize,
    };
};

const withGlobalFilter = (global: string) => {
    return {
        global,
    };
};
const withIds = (ids: string[]) => {
    return {
        ids,
    };
};

const withFilters = (filters?: IFilter[]) => {
    return {
        filters,
    };
};

const withSortBy = (sortBy?: ISortBy[]) => {
    return {
        ...(sortBy?.[0] && {
            sort: sortBy[0].id,
            desc: sortBy[0].desc ? "desc" : "asc",
        }),
    };
};

export const getListQueryString = ({
    pageIndex = 1,
    pageSize = 20,
    filters = [],
    sortBy = [],
    globalFilter = "",
    ids = [],
}: IGetListQuery) => {
    return qs.stringify(
        {
            ...withPagination(pageIndex, pageSize),
            ...withFilters(filters),
            ...withSortBy(sortBy),
            ...withGlobalFilter(globalFilter),
            ...withIds(ids),
        },
        { encode: true }
    );
};
