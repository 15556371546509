import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { baseQueryWithIntercept } from "../connection/api";

export const reviewApi = createApi({
    reducerPath: "reviewApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Companies"],
    endpoints: builder => ({
        deleteReview: builder.mutation<any, any>({
            query: ({ id, decrement }) => ({
                url: `/reviews/${id}`,
                method: "DELETE",
                params: {
                    decrement,
                },
            }),
            invalidatesTags: ["Companies"],
        }),
    }),
});

export const { useDeleteReviewMutation } = reviewApi;
