import { lazy, ComponentType, LazyExoticComponent } from "react";

interface IRouteItem {
    id: number | string;
    path: string;
    component: LazyExoticComponent<ComponentType<any>>;
    isPrivate: boolean;
}

export const ROUTES: IRouteItem[] = [
    {
        id: 1,
        path: "/",
        component: lazy(() => import("./clients-list")),
        isPrivate: true,
    },
    {
        id: 2,
        path: "/sign-up",
        component: lazy(() => import("./sign-up")),
        isPrivate: false,
    },
    {
        id: 3,
        path: "/sign-in",
        component: lazy(() => import("./sign-in")),
        isPrivate: false,
    },
    {
        id: 4,
        path: "/questionnaire/*",
        component: lazy(() => import("./questionnaire")),
        isPrivate: true,
    },
    {
        id: 5,
        path: "/profile",
        component: lazy(() => import("./profile")),
        isPrivate: true,
    },
    {
        id: 6,
        path: "/company-list",
        component: lazy(() => import("./company-list")),
        isPrivate: true,
    },
    {
        id: 7,
        path: "/company-list/:id",
        component: lazy(() => import("./company-information")),
        isPrivate: true,
    },
    {
        id: 6,
        path: "/clients",
        component: lazy(() => import("./clients-list")),
        isPrivate: true,
    },
    {
        id: 7,
        path: "/clients/:id",
        component: lazy(() => import("./client-information")),
        isPrivate: true,
    },
];
