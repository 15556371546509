import { createApi } from "@reduxjs/toolkit/query/react";

import { IGetCompaniesResponse, IGetCompanyByIdResponse } from "../../types/companies";
import { IGetListQuery, getListQueryString } from "../../types/queries";
import { baseQueryWithIntercept } from "../connection/api";

export const companyApi = createApi({
    reducerPath: "companyApi",
    baseQuery: baseQueryWithIntercept,
    tagTypes: ["Companies"],
    endpoints: builder => ({
        getCompanies: builder.query<IGetCompaniesResponse, IGetListQuery>({
            query: query => ({
                url: `/companies?${getListQueryString(query)}`,
            }),
            providesTags: ["Companies"],
        }),
        getCompanyById: builder.query<IGetCompanyByIdResponse, string>({
            query: id => ({ url: `/companies/admin/${id}` }),
            providesTags: ["Companies"],
        }),
    }),
});

export const { useGetCompaniesQuery, useGetCompanyByIdQuery } = companyApi;
