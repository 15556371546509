import { FC } from "react";

import { Navigate } from "react-router-dom";

import MainWrapper from "../components/basic/MainWrapper/MainWrapper";
import { getTokenFromCookies } from "../shared/cookies-actions";

interface PrivateRouteProps {
    component: FC<any>;
    props?: any;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ component: Component, ...props }) => {
    const token = getTokenFromCookies();

    return token ? (
        <MainWrapper>
            <Component {...props} />
        </MainWrapper>
    ) : (
        <Navigate to="/sign-in" />
    );
};

export default PrivateRoute;
