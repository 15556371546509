import "./styles/index.less";
import "./styles/scss/index.scss";

import { Suspense } from "react";

import { Route, Routes } from "react-router-dom";

import { ROUTES } from "./pages";
import PrivateRoute from "./pages/PrivateRoute";

export const App = () => {
    return (
        <Routes>
            {ROUTES.map(route => {
                const RouteComponent = route.isPrivate ? (
                    <PrivateRoute component={route.component} />
                ) : (
                    <route.component />
                );
                return (
                    <Route
                        key={route.id}
                        path={route.path}
                        element={<Suspense fallback={null}>{RouteComponent}</Suspense>}
                    />
                );
            })}
        </Routes>
    );
};
