import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";

import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { App } from "./App";
import { store } from "./store/index";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Suspense fallback="...loading">
            <ConfigProvider theme={{ token: { colorPrimary: "#6050A6" } }}>
                <Provider store={store}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </Provider>
            </ConfigProvider>
        </Suspense>
    </React.StrictMode>
);
